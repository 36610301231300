import { useCallback, useEffect, useState } from "react";
import logo from './teddy.png';
import './App.css';
import './fonts/LovelyBalloon.ttf';
import { createClient } from '@supabase/supabase-js'
import { Database } from './supabase';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import type { Container, Engine } from "@tsparticles/engine";
import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.


function App() {
const supabaseUrl = 'https://devzowlpnbqrinrupkon.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRldnpvd2xwbmJxcmlucnVwa29uIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNjIzNzksImV4cCI6MjAyMjYzODM3OX0.lKpqI-NlmXhtjLLxVhlolLSH7YpE9iD4IskpZgDQHno'
const supabase = createClient<Database>(supabaseUrl, supabaseKey)
const [ init, setInit ] = useState(false);

const checkIfYes = async () => {
  const {data, error} = await supabase.from('valentine').select().eq('id', 1);
  
  if(data && data[0].valentine){
    setInit(true);
  } else {
    setInit(false)
  }

};


useEffect(() => {
  initParticlesEngine(async (engine: Engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadAll(engine);
    //await loadFull(engine);
    await loadAll(engine);
    //await loadBasic(engine);
  })

  checkIfYes();
  
}, [])

const onClickHandler = async () => {
  await supabase
  .from('valentine')
  .update({ 'valentine': true }).eq('id', 1).then(() => {
    checkIfYes();
  })

};

  return (
    <div className="App">
        <header className="App-header">
          <div className='card'>
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Will you be my valentine?
            </p>
            {
              !init &&
              <div>
                <button className="btn-yes" onClick={() => onClickHandler()}>yes</button>
              </div>
            }
          </div>
        </header>
        {init && <div className="modal"><div className="card-it-is-a-date">Its a date!</div></div>}
        { init && <Particles
            id="tsparticles"
            options={{
                fpsLimit: 120,
                interactivity: {
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: ["b8001f;", "d40808", "ff2e51", "d0a0ec"],
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: 'bounce',
                        },
                        random: true,
                        speed: 10,
                        straight: false,
                    },
                    number: {
                        value: 200,
                    },
                    opacity: {
                        value: 0.7,
                    },
                    shape: {
                        type: "heart",
                    },
                    size: {
                        value: { min: 10, max: 70 },
                    },
                },
                detectRetina: true,
            }}
        />
}
    </div>
  );
}

export default App;
